<template>
	<div>
		<StageIntroDialog />

		<StandardMindmap v-if="map_id" :prop_map_id="map_id"></StandardMindmap>

		<StageFinishDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			current_step="app.initial_field_mapping.title"
			@close="dialog = false"
		></StageFinishDialog>
	</div>
</template>

<script>
import {
	generateRandomString,
	findFromArray,
	flatArrayOfObjects,
} from "@/utils/helpers";
import StandardMindmap from "@/components/shared/tools/standardMindmap/Index.vue";
import StageFinishDialog from "@/components/research/modules/_common/stages/FinishDialog";
import StageIntroDialog from "@/components/research/modules/_common/stages/IntroDialog";

export default {
	name: "er4tfv_StandardMapPage",

	data() {
		return {
			map_id: null,
			dialog: false,
		};
	},

	components: {
		StandardMindmap,
		StageFinishDialog,
		StageIntroDialog,
	},

	created() {
		this.readyToListenEvents();
	},

	mounted() {
		this.getMap();
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("redirect-from-er4tfv-standard-map", () => {
				this.validateMap();
			});
		},

		async validateMap() {
			try {
				this.$loader.start();

				let map = await this.getMap();

				let nodes = map.data.nodeData.children
					? flatArrayOfObjects(map.data.nodeData.children, "children")
					: [];

				if (nodes.length < 6) {
					throw new Error(
						this.$t("app.ntfy.err.min_nodes_except_title_node", {
							total: 6,
						}),
						{
							cause: "werCustom",
						},
					);
				}

				this.dialog = true;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async getMap() {
			try {
				this.$loader.start();

				// get map from the API
				let response = await this.$store.dispatch("mindmap/list", {
					category: this.$defines.SYSTEM_CATEGORY,
				});

				if (response && response.data && response.data.length) {
					// search the map of name "branco_weiss_er4tfv"
					let desired_map = findFromArray(
						response.data,
						"name",
						this.$defines.BRANCO_WEISS_MAP_NAME,
					);
					// if branco map found then use it and return from here.
					if (desired_map) {
						this.map_id = desired_map.id;
						return desired_map;
					}
				}

				await this.createMap();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async createMap() {
			let response = await this.$store.dispatch("mindmap/create", {
				name: this.$defines.BRANCO_WEISS_MAP_NAME,
				data: {
					linkData: {},
					nodeData: {
						id: generateRandomString(),
						root: true,
						topic: this.$t("app.root_node"),
					},
				},
			});

			if (response) {
				this.map_id = response.id;
			} else {
				throw new Error("app.ntfy.err.generic", {
					cause: "werCustom",
				});
			}
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("redirect-from-er4tfv-standard-mapp");
	},
};
</script>

<style></style>
